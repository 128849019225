export const CTA_BLOCK_REGULAR_SIZE = 'regular';
export const CTA_BLOCK_LARGE_SIZE = 'large';
export const CTA_BLOCK_BUTTON_VARIANT = 'button';
export const CTA_BLOCK_LINK_VARIANT = 'text';

export const DEFAULT_CTA_BLOCK_ITEMS = {
    callToAction: 'Some Text',
    linkURL: 'www.eventbrite.com/reconvene',
};
const REGULAR_BUTTON_OBJ = {
    ...DEFAULT_CTA_BLOCK_ITEMS,
    size: CTA_BLOCK_REGULAR_SIZE,
    variant: CTA_BLOCK_BUTTON_VARIANT,
};
const LARGE_BUTTON_OBJ = {
    ...DEFAULT_CTA_BLOCK_ITEMS,
    size: CTA_BLOCK_LARGE_SIZE,
    variant: CTA_BLOCK_BUTTON_VARIANT,
};
const TEXT_OBJ = {
    ...DEFAULT_CTA_BLOCK_ITEMS,
    size: CTA_BLOCK_REGULAR_SIZE,
    variant: CTA_BLOCK_LINK_VARIANT,
};

export const CTA_LINK_BLOCK_PROP_MAP = {
    '1 Link Block': [TEXT_OBJ],
    '2 Link Blocks': [TEXT_OBJ, TEXT_OBJ],
};

export const CTA_BUTTON_BLOCK_PROP_MAP = {
    '1 Regular Button Block': [REGULAR_BUTTON_OBJ],
    '2 Regular Button Blocks': [REGULAR_BUTTON_OBJ, REGULAR_BUTTON_OBJ],
    '1 Large Button Block': [LARGE_BUTTON_OBJ],
    '2 Large Button Blocks': [LARGE_BUTTON_OBJ, LARGE_BUTTON_OBJ],
};

export const CTA_BLOCK_DEFAULT_PROPS = {
    callToAction: 'Maecenas eget condimentum velit condim',
    linkURL: 'www.eventbrite.com/reconvene',
    size: CTA_BLOCK_REGULAR_SIZE,
    variant: CTA_BLOCK_BUTTON_VARIANT,
};
