import React from 'react';
import { relativeZindexStyle } from '../modules/utils';

interface ImageProps {
    image: string;
    classes?: string;
    style?: React.CSSProperties;
    alt?: string;
}
const LazyloadImage = ({ image, classes, style, alt }: ImageProps) => {
    const classNames = `${classes}`;
    const styleAttributes = style ? style : relativeZindexStyle;
    return (
        <img
            alt={alt}
            src={image}
            className={classNames}
            style={styleAttributes}
        ></img>
    );
};
export default LazyloadImage;
